/*
https://stackoverflow.com/questions/74184312/how-can-i-build-a-typescript-string-literal-type-with-an-imported-array
*/
module.exports = /** @type {const} */ ([
  "mobileNavigation",
  "clickToOpen",
  "clickToClose",
  "expand",
  "mute",
  "unmute",
  "pause",
  "play",
  "goToSlide",
  "goToPreviousSlide",
  "goToNextSlide",
  "clickToViewTestimonial",
  "clickToToggleMobileNav",
  "clickToWatchVideo",
  "mustBeValidEmail",
  "showLess",
  "showMore",
  "viewLess",
  "viewAll",
  "clickToToggleFullscreen",
  "clientSideErrorTitle",
  "clientSideErrorMessage",
  "reload",
  "support",
  "formSubmissionConfirmation",
  "formSubmissionError",
  "formFieldsRequiredEmpty",
  "formSubmit",
  "formSubmitting",
  "clearSelection",
  "fieldRequired",
  "emailNotConform",
  "phoneNotConform",
  "navLinkAdobe",
  "navLinkHome",
  "checked",
  "fioLiveStreamRegisterCTALabel",
  "fioLiveStreamWatchCTALabel",
  "fioLiveStreamAddToCalendarLabel",
]);
