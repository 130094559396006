import { gsap } from 'gsap';

const killTimeline = (timeline: GSAPTimeline, clearProps = true) => {
  const targets = timeline.getChildren();

  timeline.kill();

  if (clearProps)
    for (let i = 0; i < targets.length; i++) {
      if (targets[i].targets().length) {
        gsap.set(targets[i].targets(), { clearProps: 'all' });
      }
    }
};

export default killTimeline;
