const buttonColorSchemes = [
  {
    value: "glass",
    title: "Glass",
  },
  {
    value: "ghost",
    title: "Ghost",
  },
  {
    value: "white",
    title: "White",
  },
];

module.exports = buttonColorSchemes;
