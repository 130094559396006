const Types = Object.freeze({
  Default: "default",
  SelectedCustomer: "selectedCustomer",
  LargeEnterprise: "largeEnterprise",
  LargeEnterpriseReturningVisitor: "largeEnterpriseReturningVisitor",
  ABTesting: "abTesting",
});

const List = Object.freeze([
  {
    name: Types.Default,
    displayName: "Default",
    summary: "Default variant for all users.",
  },
  {
    name: Types.SelectedCustomer,
    displayName: "Selected Customer",
    summary: "Variant for selected customers.",
  },
  {
    name: Types.LargeEnterprise,
    displayName: "Large Enterprise",
    summary: "Variant for large enterprises. Displayed on first visit.",
  },
  {
    name: Types.LargeEnterpriseReturningVisitor,
    displayName: "Large Enterprise (Returning Visitor)",
    summary: "Variant for large enterprises. Displayed on subsequent visits.",
  },
  {
    name: Types.ABTesting,
    displayName: "A/B Testing",
    summary: "Variant for A/B testing against the default.",
  },
]);

/**
 * exporting these tokens with correct JS doc annotation so they can be read as ts and made into union types
 * https://stackoverflow.com/questions/74184312/how-can-i-build-a-typescript-string-literal-type-with-an-imported-array
 */
module.exports = /** @type {const} */ ({
  Types,
  List,
});
